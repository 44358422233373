import fastdom from 'fastdom'
import { withDependencies } from '@wix/thunderbolt-ioc'
import {
	IPageDidMountHandler,
	BrowserWindow,
	BrowserWindowSymbol,
	PageScrollRegistrarSymbol,
} from '@wix/thunderbolt-symbols'
import { ScrollMeasurements } from './types'
import { IPageScrollRegistrar } from 'feature-page-scroll'
import { forEach } from 'lodash'

const scrollVarFactory = (window: BrowserWindow, pageScrollRegistrar: IPageScrollRegistrar): IPageDidMountHandler => {
	const measurements: ScrollMeasurements = {
		scrollY: 0,
		viewPortHeight: 0,
	}

	const scrollTick = () => {
		const elements = window!.document.getElementsByClassName('scroll-css-var--scrollEffect')
		fastdom.measure(() => {
			measurements.scrollY = window!.pageYOffset
			const viewPortHeight = window!.document.documentElement.clientHeight
			measurements.viewPortHeight = viewPortHeight
			fastdom.mutate(() => {
				forEach(elements, (element: any) => {
					element.style.setProperty('--scroll', measurements.scrollY)
					element.style.setProperty('--screenH_val', measurements.viewPortHeight)
				})
			})
		})
	}

	return {
		pageDidMount() {
			pageScrollRegistrar.registerToScroll(scrollTick)
		},
	}
}

export const ScrollVar = withDependencies([BrowserWindowSymbol, PageScrollRegistrarSymbol], scrollVarFactory)
